
import {getVideoDuration, formatTime} from '/services/helpers'

export default
	props:
		layout:
			type: String
			validator: (val) -> val in ['compact', 'regular']
			default: 'regular'

		video:
			type: Array
			required: true

		title:
			type: String

		subtitle:
			type: String

		description:
			type: String

		buttons:
			type: Array

	data: ->
		duration: null

	computed:
		videoAsset: -> @video?[0]?.videoDesktop?[0] || @video?.videoMobile?[0]
		videoUrl: -> @videoAsset?.url
		computedTitle: -> @title || @video?[0]?.title
		computedSubtitle: -> @subtitle || @video?[0]?.subheading
		computedDescription: -> @description || @video?[0]?.shortDescription
		classes: -> [
			@layout
		]

	mounted: ->
		return unless @$refs.media and @videoUrl
		getVideoDuration(@videoUrl).then((duration) =>
			@duration = formatTime duration
		)

	methods:
		play: ->
			return unless @videoAsset

			@$store.commit 'videoModal/play', {
				video: @videoAsset,
				title: @computedTitle,
				subtitles: @videoAsset.subtitles
				libraryVideoId: @videoAsset.id
			}


